var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upgrade-membership-condition-container wrapper"},[_c('CRow',{},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_TITLE')))])]),_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_LABEL_PERMIT_UPGRADE')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"allow-upgrade-status"},[_c('label',{staticClass:"btn-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.upgrade.allow_upgrade_flg),expression:"upgrade.allow_upgrade_flg"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.upgrade.allow_upgrade_flg)?_vm._i(_vm.upgrade.allow_upgrade_flg,null)>-1:(_vm.upgrade.allow_upgrade_flg)},on:{"change":function($event){var $$a=_vm.upgrade.allow_upgrade_flg,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.upgrade, "allow_upgrade_flg", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.upgrade, "allow_upgrade_flg", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.upgrade, "allow_upgrade_flg", $$c)}}}}),_c('span',{staticClass:"check-silder"})])])])],1),_c('ValidationProvider',{attrs:{"name":"UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_TYPE","rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_TYPE'))+" ")])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.upgrade.num_of_free_members),expression:"upgrade.num_of_free_members"}],staticClass:"input-field form-control",domProps:{"value":(_vm.upgrade.num_of_free_members)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.upgrade, "num_of_free_members", $event.target.value)}}}),_c('p',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_INTRODUCE","rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_INTRODUCE'))+" ")])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.upgrade.num_of_paid_members),expression:"upgrade.num_of_paid_members"}],staticClass:"input-field form-control",domProps:{"value":(_vm.upgrade.num_of_paid_members)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.upgrade, "num_of_paid_members", $event.target.value)}}}),_c('p',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_AMOUNT","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_AMOUNT'))+" ")])]),_c('CCol',{staticClass:"wrap-input",attrs:{"lg":"6","md":"6"}},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":0},model:{value:(_vm.upgrade.staking_amount),callback:function ($$v) {_vm.$set(_vm.upgrade, "staking_amount", $$v)},expression:"upgrade.staking_amount"}}),_c('p',{staticClass:"symbol"},[_vm._v(_vm._s(_vm.upgrade.staking_amount_currency_symbol))]),_c('p',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_REWARD","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_REWARD'))+" ")])]),_c('CCol',{staticClass:"wrap-input",attrs:{"lg":"6","md":"6"}},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":0},model:{value:(_vm.upgrade.staking_reward),callback:function ($$v) {_vm.$set(_vm.upgrade, "staking_reward", $$v)},expression:"upgrade.staking_reward"}}),_c('p',{staticClass:"symbol"},[_vm._v(_vm._s(_vm.upgrade.staking_reward_currency_symbol))]),_c('p',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)}}],null,true)}),_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('UPGRADE_CONDITIONS_PAGE_BUTTON_SAVE')))]):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }