<template>
  <div class="upgrade-membership-condition-container wrapper">
    <CRow class>
      <CCol lg="6" md="6">
        <CCard>
          <CCardHeader>
            <h1>{{$t('UPGRADE_CONDITIONS_PAGE_TITLE')}}</h1>
          </CCardHeader>

          <CCardBody>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <CCol lg="6" md="6">
                    <label>{{$t('UPGRADE_CONDITIONS_PAGE_LABEL_PERMIT_UPGRADE')}}</label>
                  </CCol>
                  <CCol lg="6" md="6">
                    <div class="allow-upgrade-status">
                      <label class="btn-switch">
                        <input type="checkbox" class="checkbox" v-model="upgrade.allow_upgrade_flg" />
                        <span class="check-silder"></span>
                      </label>
                    </div>
                  </CCol>
                </CRow>

                <ValidationProvider
                  name="UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_TYPE"
                  rules="required|integer|min_value:0"
                >
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6">
                      <label>
                        {{$t('UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_TYPE')}}
                        <!-- <i
                          class="fas fa-exclamation-circle"
                          v-c-popover="{
                              header: 'Gold members introduced',
                              content: 'Number of Gold members introduced',
                              placement: 'right'
                          }"
                        />-->
                      </label>
                    </CCol>
                    <CCol lg="6" md="6">
                      <input class="input-field form-control" v-model="upgrade.num_of_free_members" />

                      <p class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </p>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <ValidationProvider name="UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_INTRODUCE" rules="required|integer|min_value:0">
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6">
                      <label>
                        {{$t('UPGRADE_CONDITIONS_PAGE_LABEL_MEMBER_INTRODUCE')}}
                        <!-- <i
                          class="fas fa-exclamation-circle"
                          v-c-popover="{
                              header: 'Members introduced',
                              content: 'Number of members introduced',
                              placement: 'right'
                          }"
                        />-->
                      </label>
                    </CCol>
                    <CCol lg="6" md="6">
                      <input class="input-field form-control" v-model="upgrade.num_of_paid_members" />

                      <p class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </p>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <ValidationProvider name="UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_AMOUNT" rules="required|min_value:0">
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6">
                      <label>
                        {{$t('UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_AMOUNT')}}
                        <!-- <i
                          class="fas fa-exclamation-circle"
                          v-c-popover="{
                              header: 'Staking Amount',
                              content: 'Number of Minimum Staking Amount reached',
                              placement: 'right'
                          }"
                        />-->
                      </label>
                    </CCol>
                    <CCol lg="6" md="6" class="wrap-input">
                      <currency-input
                        class="input-field form-control"
                        v-model="upgrade.staking_amount"
                        :precision="0"
                      />
                      <p class="symbol">{{upgrade.staking_amount_currency_symbol}}</p>

                      <p class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </p>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <ValidationProvider name="UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_REWARD" rules="required|min_value:0">
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6">
                      <label>
                        {{$t('UPGRADE_CONDITIONS_PAGE_LABEL_STAKING_REWARD')}}
                        <!-- <i
                          class="fas fa-exclamation-circle"
                          v-c-popover="{
                              header: 'Staking reward',
                              content: 'Number of Minimum Staking Rewards reached',
                              placement: 'right'
                          }"
                        />-->
                      </label>
                    </CCol>
                    <CCol lg="6" md="6" class="wrap-input">
                      <currency-input
                        class="input-field form-control"
                        v-model="upgrade.staking_reward"
                        :precision="0"
                      />
                      <p class="symbol">{{upgrade.staking_reward_currency_symbol}}</p>
                      <p class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </p>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      v-if="isAllowedUpdating"
                      color="primary"
                      class="btn btn-primary"
                      @click="onSubmit"
                    >{{$t('UPGRADE_CONDITIONS_PAGE_BUTTON_SAVE')}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'UpgradeConditionMembership',
  data() {
    return {
      upgrade: {
        allow_upgrade_flg: false,
        num_of_paid_members: 0,
        num_of_free_members: 0,
        staking_amount: 0,
        staking_reward: 0,
      },
      isAllowedUpdating: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();

    this.upgrade = await this.getPropertyUpgradeCondition();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_UPGRADE_CONDITION') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getPropertyUpgradeCondition() {
      try {
        const res = await this.$http.get(endpoints.getPropertyUpgradeCondition);
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.updateUpgradeCondition();
      });
    },
    async updateUpgradeCondition() {
      const params = this.upgrade;
      try {
        await this.$http.post(endpoints.updateUpgradeCondition, params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Membership condition has been upgraded',
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.upgrade-membership-condition-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .form-group {
      display: flex;
      flex: 0 0 100%;
      margin: 0 0 25px 0;
      .col-md-6 {
        padding: 0;
        label {
          position: relative;
          width: 100%;
          padding-right: 9px;
          text-align: right;
          font-size: 14px;
          font-weight: 300;
          color: #3e4b65;
          line-height: 18px;
          margin: 0;
          @media only screen and (max-width: 767px) {
            text-align: left;
          }
          .vue-popover {
            width: 100% !important;
            right: -15px !important;
            top: 0 !important;
            left: unset !important;
            background: #dadada;
            &::before {
              border-left-color: #dadada;
            }
          }
        }
        input {
          width: 145px;
          font-size: 16px;
          text-align: right;
          font-weight: 500;
          display: inline-block;
          color: #3a4a7f;
          @media only screen and (max-width: 767px) {
            width: calc(100% - 40px);
          }
        }
      }

      .wrap-input {
        .symbol {
          display: inline;
          width: calc(100% - 155px);
          line-height: 35px;
          top: 3px;
          right: 0px;
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          padding-left: 5px;
          @media only screen and (max-width: 767px) {
            width: 40px;
          }
        }
      }
      .allow-upgrade-status {
        width: 34px;
        padding-top: 2px;
        h6 {
          margin: 0;
          line-height: 27px;
        }
        .btn-switch {
          position: relative;
          display: inline-block;
          width: 34px;
          height: 19px;
          .checkbox {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .check-silder::before {
              border-color: #009900;
              transform: translateX(15px);
            }
            &:checked + .check-silder {
              background: #009900;
            }
          }
          .check-silder {
            position: absolute;
            cursor: pointer;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: #fff;
            border: 1px solid #dadada;
            transition: 0.4s;
            border-radius: 24px;
            &:before {
              position: absolute;
              content: '';
              height: 15px;
              width: 15px;
              left: 1px;
              bottom: 1px;
              top: 1px;
              background: #fff;
              border: 1px solid #dadada;
              transition: 0.4s;
              border-radius: 50%;
            }
          }
        }
      }
      .error-msg-wrap{
        width: 100%;
        padding-right: calc(100% - 155px);
        margin-bottom: 0;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }
}
</style>
